// export default {
//     driverHome: 'http://localhost:3018/',
//     helperHome: 'http://localhost:3020/',
//     packerHome: 'http://localhost:3022/',
//     thirdpartyHome: 'http://localhost:3024/',
//     claimrepairHome: 'http://localhost:3026/',
// }

export default {
    driverHome: 'https://myrelopro.com/driver-home',
    helperHome: 'https://myrelopro.com/helper-home',
    packerHome: 'https://myrelopro.com/packer-home',
    thirdpartyHome: 'https://myrelopro.com/thirdparty-home',
    claimrepairHome: 'https://myrelopro.com/claimrepair-home',
    paypal: {
        env: 'production',  // sandbox
        currency: 'USD',
        client: {
            sandbox: 'AfimwhKCg4Gom37CBaITDbtEP3RT4wvDPo9X4mSZa7gCG8Zdlwww2Wea7EPhSEqtRCgNAQIueLc0-a7c',
            production: 'AU6GAzqguPcD0CCcciIz2GdSWHy0GlR7P4-Z5mRkWtVE6xYq5QmdV0HlKii27IKFmhNmkHHK-b6IHRVQ',
        }
    }
}

export const getDashboardUrl = (profileName) => {
    if (process.env.NODE_ENV === 'development') {
        const urls = {
            driver: 'http://localhost:3019/#/',
            helper: 'http://localhost:3021/#/',
            packer: 'http://localhost:3023/#/',
            thirdparty: 'http://localhost:3025/#/',
            claimrepair: 'http://localhost:3027/#/',
        }
        return urls[profileName.toLowerCase()]
    }
    if (process.env.NODE_ENV === "production") {
        const urls = {
            driver: 'https://myrelopro.com/driver-home/driver/',
            helper: 'https://myrelopro.com/helper-home/helper/',
            packer: 'https://myrelopro.com/packer-home/packer/',
            thirdparty: 'https://myrelopro.com/thirdparty/',
            claimrepair: 'https://myrelopro.com/claimrepair-home/claimrepair/',
        }
        return urls[profileName.toLowerCase()]
    }
}

export const getCommonProAuthUrl = (proType) => {
    let proTypeLower = proType && proType.toLowerCase();
    if (process.env.NODE_ENV === 'development') {
        return {
            signin: 'http://localhost:3040/#/login?proType=' + proTypeLower,
            signup: 'http://localhost:3040/#/registration?proType=' + proTypeLower,
        }
    }
    if (process.env.NODE_ENV === "production") {
        return {
            signin: 'https://myrelopro.com/common/#/login?proType=' + proTypeLower,
            signup: 'https://myrelopro.com/common/#/registration?proType=' + proTypeLower,
        }
    }
}